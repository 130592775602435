import { useAuth0 } from '@auth0/auth0-react'
import ErrorIcon from '@mui/icons-material/Error'
import LockIcon from '@mui/icons-material/Lock'
import { Avatar, Button, Card, CircularProgress, TextField, ThemeProvider, Typography } from '@mui/material'
import { createTheme, styled } from '@mui/material/styles'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getEnvVariable } from 'xxllnc-react-components'
import { Xxllnc } from '../components'
import { scopes } from '../config'
import { button, color, theme as xxTheme, xxllncColor } from '../layout/themes'
import { getApiUrl } from '../providers/data'
import { debug } from '../test'
import { AuthOrganization } from '../types/ecoTypes'
import authTokenUtil from '../utils/authToken'
import organizationFetch from '../utils/organizationFetch'

const returnTo = '/login'
const getAudience = (): string => getEnvVariable('REACT_APP_AUTH0_AUDIENCE', '')

const LoginPage = (): JSX.Element => {
  const [authToken, setAuthToken] = useState<string | null>(null)
  const [customError, setCustomError] = useState<string | null>(null)
  const [orgIsLoading, setOrgIsLoading] = useState(false)
  const [email, setEmail] = useState<string>(authTokenUtil.getOrganization().domain)
  const [orgError, setOrgError] = useState(false)
  const getSearch = new URLSearchParams(location?.search)
  const appId = getSearch.get('app_id') as string

  const navigate = useNavigate()
  const translate = useTranslate()
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    logout
  } = useAuth0()

  useEffect(() => {
    // TODO: We should switch to error.name, but it's not in the error at this time
    if (error?.message === 'Cannot read property \'organization_uuid\' of undefined') {
      logout({ federated: false, returnTo })
    } else if (error?.message === 'authorization request parameter organization must be an organization id') {
      setCustomError(translate('login.unknownOrganizationId'))
    }
  }, [authToken, error])

  useEffect(() => {
    const existingToken = authTokenUtil.getToken()
    if (existingToken) return setAuthToken(existingToken)
    if (isAuthenticated && user) {
      void (async () => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const organization = user.org_id ? { organization: user.org_id } : null
          const token = await getAccessTokenSilently({
            ...organization,
            audience: getAudience(),
            scope: scopes
          })
          if (token) {
            authTokenUtil.setToken(token)
            setAuthToken(token)
          }
        } catch (err) {
          setCustomError(translate('login.incognitoError'))
        }
      })()
    }
  }, [isAuthenticated])

  const onChangeInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrgError(false)
    setEmail(event.target.value as string)
    authTokenUtil.deleteOrganization()
  }

  const onClickLogin = async (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    event.preventDefault()

    if (!email) return
    setOrgIsLoading(true)

    const org = authTokenUtil.getOrganization().organization
    debug && console.log({org, appId, user, isAuthenticated})
    if (org && org.organizationId === '' && appId) {
      debug && console.log('Fetching linked organization from api')
      // user has provided an appId, which indicates a possible organization link
      await organizationFetch(getApiUrl('login_info'), email, appId)
        .then((organization: AuthOrganization[]) => {
          debug && console.log(organization.length)

          if (organization.length > 1) {
            authTokenUtil.setLoginInfo(appId, email)
            return navigate('/auth/organization')
          }
        })
        .catch((err: string) => {
          debug && console.log(err)
          setOrgError(true)
        })
        .catch((_) => setOrgError(true))
        .finally(() => {
          setOrgIsLoading(false)
        })
    } else if (org && org.organizationId === '') {
      debug && console.log('Fetching organization from api')
      await organizationFetch(getApiUrl('login_info'), email)
        .then((organization: AuthOrganization) => {
          // make sure that the selected organization is stored so it is used when user gets back
          authTokenUtil.setOrganization(organization, email)
          // eslint-disable-next-line camelcase
          loginWithRedirect({organization: organization.auth0Identifier, login_hint: email})
            .catch((err: string) => {
              debug && console.log(err)
              setOrgError(true)
            })
        })
        .catch((err: string) => {
          debug && console.warn(err)
          setOrgError(true)
        })
        .catch((_) => setOrgError(true))
        .finally(() => {
          setOrgIsLoading(false)
        })
    } else {
      debug && console.log('Login with redirect to auth0')
      // eslint-disable-next-line camelcase
      loginWithRedirect({organization: org.auth0Identifier, login_hint: email})
        .catch((err: string) => {
          debug && console.warn(err)
          setOrgError(true)
        })
    }
  }

  debug && console.log({authToken})
  if (authToken) return <Navigate to="/" />

  return (
    <Root className={classes.root}>
      <div className={classes.header}>
        <Xxllnc className={classes.logo} />
      </div>
      <Card className={classes.card}>
        <form>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <>
            {!isAuthenticated ?
              <>
                <Typography
                  align="center"
                  className={classes.hint}
                  component="p"
                  variant="body2"
                >
                  {translate('login.hint')}
                </Typography>
                <TextField
                  className={classes.input}
                  error={orgError}
                  label={translate('login.email')}
                  helperText={orgError ? translate('login.emailHint') : undefined}
                  onChange={onChangeInput}
                  defaultValue={email}
                />
              </> :
              <div className={classes.hint}>
                <Typography variant="subtitle2" align="center">
                  {translate('login.loginCompleted')}
                </Typography>
                <Typography variant="body2" component="p" align="center">
                  {translate('login.fetchUserInfo')}
                </Typography>
              </div>
            }
          </>
          <>
            {error || customError &&
              <div className={classes.error}>
                <Typography variant="body2" color="error" component="p">
                  <ErrorIcon className={classes.errorIcon} />
                  { customError || translate('ra.auth.sign_in_error')}
                </Typography>
              </div>
            }
          </>
          <Button
            variant="contained"
            type="submit"
            className={classes.button}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onClickLogin}
            disabled={orgIsLoading || isLoading || (user && !authToken && !error)}
            fullWidth
          >
            <Progress
              orgIsLoading={orgIsLoading}
              isLoading={isLoading}
              user={user}
              authToken={authToken}
              error={error}
            />
          </Button>
        </form>
      </Card>
    </Root>
  )
}

const Progress = ({orgIsLoading, isLoading, user, authToken, error}): JSX.Element => {
  const translate = useTranslate()

  if(orgIsLoading || isLoading || (user && !authToken && !error)) {
    return <CircularProgress size={16} thickness={2} />
  } else {
    return <>{translate('ra.auth.sign_in')}</>
  }
}

const LoginWithTheme = (): JSX.Element => (
  <ThemeProvider theme={createTheme(xxTheme)}>
    <LoginPage />
  </ThemeProvider>
)

const PREFIX = 'xxllncLoginPage'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  logo: `${PREFIX}-logo`,
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
  hint: `${PREFIX}-hint`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  error: `${PREFIX}-error`,
  errorIcon: `${PREFIX}-errorIcon`,
  actions: `${PREFIX}-actions`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    backgroundColor: color.common.login,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    minWidth: 300,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 0,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  [`& .${classes.logo}`]: {
    fill: color.common.inactive,
    width: theme.spacing(13),
  },
  [`& .${classes.card}`]: {
    borderRadius: 5,
    boxSizing: 'border-box',
    marginBottom: theme.spacing(9),
    minWidth: 400,
    padding: theme.spacing(5),
  },
  [`& .${classes.avatar}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.icon}`]: {
    backgroundColor: color.secondary.main,
  },
  [`& .${classes.hint}`]: {
    color: '#2D333A',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.input}`]: {
    boxSizing: 'border-box',
    marginBottom: theme.spacing(3),
    ['& .MuiInputBase-root']: {
      borderRadius: 3,
      margin: 0,
      height: 54,
      ['& input']: {
        height: '100%',
        boxSizing: 'border-box',
      }
    },
    ['& .MuiInputLabel-formControl']: {
      backgroundColor: xxllncColor.light100,
      left: theme.spacing(2),
      padding: theme.spacing(1),
      lineHeight: 1,
      transform: 'translate(0, 39%) scale(1)',
    },
    ['& .MuiInputLabel-shrink']: {
      transform: 'translate(-10%, -40%) scale(.88)',
      zIndex: 99,
    },
    ['& .Mui-focused']: {
      color: '#007bad',
      border: 0,
      ['& .MuiOutlinedInput-notchedOutline']: {
        border: '2px solid #007bad',
      },
      ['& input']: {
        color: 'rgb(45, 51, 58)',
      }
    },
    ['& .MuiInput-input']: {
      boxSizing: 'border-box',
      height: '100%',
      padding: theme.spacing(1, 1.5),
      width: '100%',
    }
  },
  [`& .${classes.button}`]: {
    ['&.MuiButton-contained']: {
      backgroundColor: button.info.default.backgroundColor,
      borderRadius: 3,
      color: button.info.default.color,
      fontSize: 16,
      lineHeight: '16px',
      padding: theme.spacing(2),
      textTransform: 'capitalize',
      ['&:hover']: {
        backgroundColor: button.info.hover.backgroundColor,
        color: button.info.hover.color,
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 0px 150px inset',
        transition: 'background-color .25s ease-in-out,box-shadow .25s ease-in-out',
      },
      ['& svg']: {
        color: xxllncColor.light100,
      }
    },
    ['&.Mui-disabled']: {
      backgroundColor: button.info.disabled.backgroundColor,
      color: button.info.disabled.color,
    }
  },
  [`& .${classes.error}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    ['& .MuiTypography-colorError']: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  [`& .${classes.errorIcon}`]: {
    height: 16,
    marginRight: theme.spacing(.5),
    width: 16,
  },
  [`& .${classes.actions}`]: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}))

export default LoginWithTheme
