import { createTheme } from '@mui/material/styles'
import { xxllncColor } from './color'
import { typography } from './typography'
import { border } from './border'

const material = createTheme()

export const RaGlobalRoot = {
  [material.breakpoints.up('md')]: {
    maxWidth: '66.6%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  ['& .MuiToolbar-root']: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: typography.headline,
    minHeight: 'auto',
    [material.breakpoints.up('sm')]: {
      backgroundColor: xxllncColor.light100,
      borderTopLeftRadius: border.radius.md,
      borderTopRightRadius: border.radius.md,
    },
  },
}
