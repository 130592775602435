/* eslint-disable camelcase */
import dutchMessages from 'ra-language-dutch'

export const i18nDutch = {
  ...dutchMessages,
  resources: {
    userprofile: {
      name: 'Profiel |||| Profielen',
      title: 'Instellingen',
      list: 'Profielen',
      create: 'Profiel toevoegen',
      edit: '%{name} bewerken',
    },
    notification: {
      name: 'Notificatie |||| Notificaties',
      title: 'Notificaties',
      list: 'Notificaties',
      create: 'Notificatie',
      edit: '%{name} notificaties',
      notifications: '%{object}',
      toggleNotification: {
        succes: 'Notificatie opgeslagen',
        error: 'Er is iets fout gegaan tijdens het opslaan van de notificatie.',
      },
      action: {
        created: 'aangemaakt',
        deleted: 'verwijderd',
        updated: 'geüpdatet',
        off: 'Notificatie uit',
        on: 'Notificatie aan',
      }
    },
  },
  data: {
    email: 'E-mail',
    emailAddress: 'E-mail',
    ended: 'Einddatum',
    name: 'Naam',
    appName: 'App',
    organization: 'Organisatie',
    organizationName: 'Organisatie',
    started: 'Begindatum',
    object: 'App',
    action: 'Actie',
    enabled: 'Inschakelen',
    notification: 'Notificatie',
  },
  validation: {
    color: 'Moet een HTML kleurcode zijn.',
    hostname: 'Ongeldige hostname.',
    systemName: 'Mag alleen uit letters, cijfers en \'-\' bestaan.',
  },
  show: {
    details: 'Overzicht',
    account: 'Account',
    notification: 'Notificaties',
  },
  edit: {
    title: 'Bewerk %{name}',
  },
  submenu: {
    settings: 'Beheer',
  },
  actions: {
    goBack: 'Ga terug',
    resetPassword: 'Reset wachtwoord',
    created: 'Aangemaakt',
    deleted: 'Verwijderd',
    updated: 'Geüpdatet',
  },
  notification: {
    frequency: 'Meld frequentie',
    frequencyText: 'Notificaties worden gelijk verstuurd, op basis van je selectie krijg je ' +
      'verschillende type notificaties binnen in de e-mail inbox.',
    warning: 'Er iets mis gegaan.',
  },
  login: {
    email: 'Email',
    emailHint: 'Vul je emailadres in om door te gaan.',
    hint: 'Log in om verder te gaan',
    loginCompleted: 'Succesvol ingelogd!',
    fetchUserInfo: 'Een moment geduld, we halen je gebruikersinformatie op.',
    incognitoError: 'Authenticatie niet mogelijk in incognito mode',
    unknownOrganizationId: 'Het opgegeven organisatie-id is onbekend',
  },
  page: {
    unauthorized: {
      headerTitle: 'Onbevoegd',
      defaultTitle: 'Onbevoegd',
      title: 'Voor deze actie ben je niet bevoegd.',
      text: 'Je hebt geprobeerd een actie uit te voeren waar je geen rechten voor hebt.',
    },
    digid: {
      title: 'Inloggen met DigiD',
      text: 'Voor het door u gekozen onderdeel is verificatie van uw identiteit vereist. \n' +
        'Wanneer u op de button van DigiD klikt, wordt u doorgestuurd naar de omgeving van \n' +
        'DigiD. Nadat u bent ingelogd, wordt u automatisch teruggestuurd naar het door u gekozen onderdeel.',
      infoTitle1: 'Wat is DigiD?',
      infoText1: 'DigiD staat voor Digitale Identiteit; het is een gemeenschappelijk systeem \n' +
        'waarmee de overheid op internet uw identiteit kan verifiëren. Met uw DigiD kunt u bij \n' +
        'steeds meer overheidsinstellingen terecht.',
      infoTitle2: 'Nog geen DigiD inlogcode?',
    },
  }
}
