import { styled } from '@mui/material/styles'
import { Layout, LayoutProps } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'
import Sidebar from './Sidebar'

const layout = (props: LayoutProps): JSX.Element => (
  <StyledLayout
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    menu={Menu}
    className={classes.content}
  />
)

const PREFIX = 'xxllncLayout'

const classes = {
  content: `${PREFIX}-content`,
}

const StyledLayout = styled(Layout)(({ theme }) => ({
  [`&.${classes.content}`]: {
    position: 'relative',
    padding: 0,
    ['& #main-content']: {
      position: 'relative',
      ['&::before']: {
        left: theme.spacing(-3),
        bottom: theme.spacing(-3),
        height: '12px',
        width: '50px',
        content: '""',
        position: 'absolute',
        background: 'url(https://xxllnc.nl/wp-content/uploads/2021/04/xxllnc-kleur.svg) no-repeat center center',
        filter: 'invert(85%) sepia(0%) saturate(303%) hue-rotate(317deg) brightness(94%) contrast(110%)',
      },
    },
  },
}))

export default layout
