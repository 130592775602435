import { createTheme } from '@mui/material'
import { xxllncColor, color } from './color'
import { border } from './border'
import { typography } from './typography'

const material = createTheme()

export const button = {
  primary: {
    default: {
      backgroundColor: xxllncColor.dark100,
      color: xxllncColor.light100,
    },
    hover: {
      backgroundColor: xxllncColor.dark80,
      color: xxllncColor.light100,
    },
    disabled: {
      backgroundColor: xxllncColor.dark10,
      color: xxllncColor.light100,
    },
  },
  positive: {
    default: {
      backgroundColor: xxllncColor.green100,
      color: xxllncColor.light100,
    },
    hover: {
      backgroundColor: xxllncColor.green80,
      color: xxllncColor.light100,
    },
    disabled: {
      backgroundColor: xxllncColor.dark10,
      color: xxllncColor.light100,
    },
  },
  negative: {
    default: {
      backgroundColor: xxllncColor.red100,
      color: xxllncColor.light100,
    },
    hover: {
      backgroundColor: xxllncColor.red80,
      color: xxllncColor.light100,
    },
    disabled: {
      backgroundColor: xxllncColor.dark10,
      color: xxllncColor.light100,
    },
  },
  warning: {
    default: {
      backgroundColor: xxllncColor.orange100,
      color: xxllncColor.light100,
    },
    hover: {
      backgroundColor: xxllncColor.orange80,
      color: xxllncColor.light100,
    },
    disabled: {
      backgroundColor: xxllncColor.dark10,
      color: xxllncColor.light100,
    },
  },
  info: {
    default: {
      backgroundColor: xxllncColor.blue100,
      color: xxllncColor.light100,
    },
    hover: {
      backgroundColor: xxllncColor.blue80,
      color: xxllncColor.light100,
    },
    disabled: {
      backgroundColor: xxllncColor.dark10,
      color: xxllncColor.light100,
    },
  },
  digid: {
    default: {
      fontFamily: typography.button,
      background: color.black,
      border: border.thin + ' solid ' + color.black,
      color: color.white,
      padding: material.spacing(4),
    },
    hover: {
      background: xxllncColor.dark80,
      borderColor: xxllncColor.dark80,
    },
  },
  delete: {
    default: {
      background: 'transparent',
      padding: material.spacing(.75, 2),
      color: xxllncColor.dark100,
      fill: xxllncColor.red100,
    },
    hover: {
      background: 'rgba(194, 67, 66, 0.1)',
    }
  }
}
