/* eslint-disable */
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { createElement, useState } from 'react'
import { Logout, MenuItemLink, MenuProps, useResourceDefinitions, useSidebarState } from 'react-admin'
import SubMenu from './SubMenu'
import { xxllncColor } from './themes'

interface Resources {
  name: string,
  icon: string,
  options?: {
    label: string,
    submenu?: string,
  }
}

type MenuName = 'menuSettings'

const Menu = ({ dense = false }: MenuProps): JSX.Element => {
  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name])
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [open] = useSidebarState()
  const [state, setState] = useState({
    menuSettings: false,
  })

  const handleToggle = (menu: MenuName) => {
    setState(stateItem => ({ ...stateItem, [menu]: !stateItem[menu] }))
  }

  const getMenuItems = (object) => {
    let values = object.filter(resource => resource.icon && !resource.options?.submenu)
    const submenu = object.filter(resource => resource.icon && resource.options?.submenu)

    if (submenu.length > 0) {
      values = [
        ...values,
        {
          name: 'submenu.settings',
          resources: submenu.map(prop => prop.name),
          submenu: [
            ...submenu
          ]
        }
      ]
    }

    return values
  }

  return (
    <StyledBox mt={1} className={classes.root}>
      {isSmall &&
        <Typography style={{marginLeft: 5}} variant="h6" gutterBottom>
          Mijn xxllnc
        </Typography>
      }
      {getMenuItems(resources).map(resource => {
        if ('submenu' in resource) {
          return (
            <SubMenu
              key={resource.name}
              handleToggle={() => handleToggle('menuSettings')}
              isOpen={state.menuSettings}
              sidebarIsOpen={open}
              name={resource.name}
              icon={<SettingsOutlinedIcon />}
              dense={dense}
            >
              {resource && resource.submenu && resource.submenu.map(item => (
                <StyledMenuItemLink
                  className={classes.link}
                  classes={classes}
                  dense={dense}
                  key={item.name}
                  leftIcon={createElement(item.icon)}
                  primaryText={item.options?.label || item.name}
                  sidebarIsOpen={open}
                  to={`/${item.name}`}
                />
              ))}
            </SubMenu>
          )}

        return (
          <StyledMenuItemLink
            className={classes.item}
            dense={dense}
            key={resource.name}
            leftIcon={createElement(resource.icon)}
            primaryText={resource.options?.label || resource.name}
            sidebarIsOpen={open}
            to={`/${resource.name}`}
          />
        )
      })}
      {isSmall && <Logout />}
    </StyledBox>
  )
}

const PREFIX = 'xxllncMenu'

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  item: `${PREFIX}-item`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.link}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${classes.item}`]: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
}))

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  ['&.RaMenuItemLink-active']: {
    backgroundColor: xxllncColor.dark80,
    color: xxllncColor.light100,
    ['& svg']: {
      fill: xxllncColor.light100,
    }
  },
  ['& .RaMenuItemLink-icon']: {
    minWidth: theme.spacing(5)
  },
}))

export default Menu
