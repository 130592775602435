import { styled } from '@mui/material/styles'
import { SaveButton, Toolbar } from 'react-admin'

export const SimpleSaveToolbar = (): JSX.Element => (
  <StyledToolbar className={classes.root}>
    <SaveButton className={classes.button} />
  </StyledToolbar>
)

const PREFIX = 'xxllncSimpleSaveToolbar'

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  [`& .${classes.button}`]: {},
}))
