import { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslate } from 'ra-core'
import { TextField } from 'react-admin'
import { UserProfileOut } from '../../types/ecoRequests'
import providers from '../../providers'

const Account = (): JSX.Element => {
  const [record, setRecord] = useState<UserProfileOut>()
  const [loading, setLoading] = useState(true)
  const translate = useTranslate()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    providers.data.get('user_profile')
      .then(({ data }) => {
        setRecord(data)
        setLoading(false)
      })
      .catch(err => {
        console.warn(err)
        setLoading(false)
      })
  }, [])

  return !loading ? (
    <Root className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="div"
          variant="caption"
        >
          {translate('data.name')}
        </Typography>
        <TextField
          emptyText="-"
          record={record}
          source="name"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="div"
          variant="caption"
        >
          {translate('data.organization')}
        </Typography>
        <TextField
          emptyText="-"
          record={record}
          source="organizationName"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography
          className={classes.label}
          color="textSecondary"
          component="div"
          variant="caption"
        >
          {translate('data.email')}
        </Typography>
        <TextField
          emptyText="-"
          record={record}
          source="emailAddress"
        />
      </Grid>
    </Root>
  ) : <></>
}

const PREFIX = 'xxllncNotification'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  label: `${PREFIX}-label`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  [`& .${classes.item}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4.5),
    maxWidth: '100%',
  },
  [`& .${classes.label}`]: {
    fontSize: 10,
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
  },
}))

export default Account
