import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ShowProps, ShowView } from 'react-admin'
import Account from './Account'
import UserProfileShowActions from './UserProfile.actions'

const UserProfileShow = (props: ShowProps): JSX.Element => {
  const resource = 'user_profile'

  return (
    <StyledShowView
      actions={<UserProfileShowActions />}
      resource={resource}
      title="resources.userprofile.title"
      className={classes.root}
      {...props}
    >
      <Grid container>
        <Grid item xs={12}>
          <Account />
        </Grid>
      </Grid>
    </StyledShowView>
  )
}

const PREFIX = 'xxllncUserProfileShow'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledShowView = styled(ShowView)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  ['& .RaShow-card']: {
    boxSizing: 'border-box',
    padding: theme.spacing(3),
  },
}))

export default UserProfileShow
