import { UserProfileOut } from '../../types/ecoRequests'

export const mockUserProfile: UserProfileOut[] = [
  {
    id: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8',
    name: 'Tessa Tester',
    emailAddress: 'tester@zaakstad.nl',
    organizationName: 'Zaakstad',
  },
]
