import ArrowBack from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'

export const BackButton = (props: BackButtonProps): JSX.Element => {
  const { className, to } = props
  const [mountHistoryLength, setMountHistoryLength] = useState<number>(0)
  useEffect((): void => setMountHistoryLength(window.history.length), [])
  const onClick = (): boolean | void => !to && window.history.go(mountHistoryLength - window.history.length - 1)

  return (
    <StyledButton
      variant="text"
      onClick={onClick}
      component={Link}
      to={{
        pathname: to,
      }}
      className={clsx(classes.button, className)}
    >
      <ArrowBack className={classes.icon} />
    </StyledButton>
  )
}

interface BackButtonProps {
  className?: string;
  classes?: {
    button?: string;
    icon?: string;
  };
  to?: string;
}

BackButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  to: PropTypes.string,
}

const PREFIX = 'xxllncBackButton'

const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    marginRight: theme.spacing(1),
    ['& .MuiButton-startIcon']: {
      marginRight: 0,
    },
  },
  [`&.${classes.icon}`]: {}
}))
