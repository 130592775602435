import { styled, Theme } from '@mui/material/styles'
import { Sidebar as RaSidebar, useSidebarState } from 'react-admin'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Sidebar = props => {
  const [open] = useSidebarState()
  const styleProps = {
    open,
  }

  return (
    <StyledSidebar
      classes={classes}
      className={classes.root}
      size={200}
      {...props}
      {...styleProps}
    />
  )
}

interface StyleProps {
  open?: boolean,
}

const PREFIX = 'xxllncSidebar'

const classes = {
  root: `${PREFIX}-root`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  fixed: `${PREFIX}-fixed`,
}

const StyledSidebar = styled(RaSidebar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme, open }: StyleProps & { theme: Theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.drawerPaper}`]: {
    border: 'none',
  },
  ['& .RaSidebar-fixed']: {
    boxSizing: 'border-box',
    height: 'calc(100vh - 3em)',
    overflowX: 'hidden',
    position: 'fixed',
    width: 'inherit',
    paddingLeft: open ? theme.spacing(2) : 12,
    paddingRight: open ? theme.spacing(2) : 12,
    ['& .MuiBox-root']: {
      marginTop: 0,
    },
  },
}))

export default Sidebar
