import { Grid } from '@mui/material'
import { TextInput } from 'react-admin'

const UserProfileForm = (): JSX.Element => (
  <>
    <Grid item xs={12}>
      <TextInput
        source="name"
        label="data.name"
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        source="email"
        label="data.email"
        disabled
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        source="organizationName"
        label="data.organization"
        disabled
      />
    </Grid>
  </>
)

export default UserProfileForm
