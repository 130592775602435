import Admin from './admin/Admin'
import { Auth0Provider } from '@auth0/auth0-react'
import { getEnvVariable } from 'xxllnc-react-components'
import { scopes } from './config'

const getAudience = (): string => getEnvVariable('REACT_APP_AUTH0_AUDIENCE', '')
const getDomain = (): string => getEnvVariable('REACT_APP_AUTH0_DOMAIN', 'unset')
const getClientId = (): string => getEnvVariable('REACT_APP_AUTH0_CLIENT_ID', 'unset')

const App = (): JSX.Element => (
  <Auth0Provider
    audience={getAudience()}
    scope={scopes}
    domain={getDomain()}
    clientId={getClientId()}
    redirectUri={window.location.origin}
    cacheLocation='localstorage'
    useRefreshTokens={true}
    onRedirectCallback={() => {
      console.log('Redirect ')
    }}
  >
    <Admin/>
  </Auth0Provider>
)

export default App
