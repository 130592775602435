import SettingsIcon from '@mui/icons-material/Settings'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'
import { forwardRef, ReactElement } from 'react'
import { MenuItemLink } from 'react-admin'

/**
 * TODO: update the `to` to a more dynamic link.
 */

/**
 * Link to the configuration page.
 *
 * @example // basic usage
 * import { ConfigurationMenu } from '../../components';
 *
 * <ConfigurationMenu />
 */

export const ConfigurationMenu = forwardRef<any, any>((props: Props, ref): JSX.Element => {
  const translate = useTranslate()
  const {
    icon = defaultIcon,
    onClick,
    label = translate('resources.configuration.title'),
    to = '/configuration/26e571de-d298-4e33-a83b-748c0510d7b8/show'
  } = props

  return (
    <Root
      className={classes.root}
      leftIcon={icon}
      onClick={onClick}
      primaryText={label}
      ref={ref}
      to={to}
      sidebarIsOpen
    />
  )
})

const defaultIcon = <SettingsIcon />

interface Props {
  icon?: ReactElement;
  label?: string;
  onClick?: () => void;
  to?: string;
}

ConfigurationMenu.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

const PREFIX = 'xxllncConfigurationMenu'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled(MenuItemLink)(({ theme }) => ({
  [`&.${classes.root}`]: {},
}))

