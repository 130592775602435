import ReplayIcon from '@mui/icons-material/Replay'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import PropTypes from 'prop-types'
import { RaRecord, useNotify } from 'ra-core'
import { ReactElement, useCallback, useState } from 'react'
import { Button as RaButton, Confirm } from 'react-admin'
import { getApiUrl } from '../../providers/data'
import authToken from '../../utils/authToken'

/**
 * Resets password for user.
 *
 * @example // basic usage
 * import { ResetPasswordButton } from '../../components';
 *
 * const ResetPasswordButton = (): JSX.Element => (
 *   <ResetPasswordButton record={record} resource={resource} onClose={onClose} />
 * );
 */
export const ResetPasswordButton = (props: ResetPasswordButtonProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false)
  const {
    icon = defaultIcon,
    label = 'actions.resetPassword',
    confirmTitle = 'resources.auth_member.notification.confirmTitle',
    confirmContent = 'resources.auth_member.notification.confirmContent',
    onClose,
    record,
    resource,
    variant,
    ...rest
  } = props
  const token = authToken.getToken() || ''
  const notify = useNotify()

  const url = `${getApiUrl(resource)}/${resource}/${record.id}/password_reset`

  const handleReset = useCallback(
    () => {
      fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Authorization: token,
        },
      }).catch(error => {
        console.error(error)
        notify('auth_member.notification.changePasswordError', { type: 'warning' })
      })
      setOpen(false)
    },
    [resource, record]
  )

  const handleResetDefault = (): void => {
    setOpen(true)
    onClose()
  }

  const handleDialogClose = (): void => {
    setOpen(false)
    onClose()
  }

  return (
    <>
      <RaButton
        label={label}
        variant={variant}
        color="primary"
        onClick={handleResetDefault}
        {...(rest as any)}
      >
        {icon}
      </RaButton>
      <Confirm
        isOpen={isOpen}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleReset}
        onClose={handleDialogClose}
      />
    </>
  )
}

const defaultIcon = <ReplayIcon />

interface Props {
  icon?: ReactElement;
  label?: string;
  onClose: () => void;
  record: RaRecord;
  resource: string;
  variant?: string;
  confirmTitle?: string;
  confirmContent?: string;
}

type ResetPasswordButtonProps = Props & MuiButtonProps

ResetPasswordButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
}
