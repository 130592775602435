import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AppBar as RaAppBar, AppBarProps } from 'react-admin'
import { border, xxllncColor } from '../layout/themes'
import { UserMenu } from './UserMenu'

const AppBar = (props: AppBarProps): JSX.Element => {
  const theme = useTheme()
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledAppBar
      className={classes.root}
      container="div"
      userMenu={<UserMenu />}
      elevation={1}
      {...props}
    >
      <Typography
        component="span"
        color="textPrimary"
        className={classes.branding}
      >
        mijn.xxllnc
      </Typography>
      <Typography
        component="span"
        color="textPrimary"
        className={classes.lab}
      >
        Lab
      </Typography>
      {!isXSmall && (
        <Typography
          id="react-admin-title"
          component="span"
          color="textPrimary"
          className={classes.title}
        />
      )}
      <span className={classes.spacer} />
    </StyledAppBar>
  )
}

const PREFIX = 'xxllncAppBar'

const classes = {
  root: `${PREFIX}-root`,
  branding: `${PREFIX}-branding`,
  lab: `${PREFIX}-lab`,
  title: `${PREFIX}-title`,
  spacer: `${PREFIX}-spacer`,
}

const StyledAppBar = styled(RaAppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  ['& > header']: {
    borderBottom: border.thin + ' solid ' + border.light,
  },
  ['& .RaAppBar-toolbar']: {
    height: theme.spacing(8),
  },
  [`& .${classes.branding}`]: {
    fontWeight: 600,
    marginRight: theme.spacing(.5),
  },
  [`& .${classes.lab}`]: {
    backgroundColor: xxllncColor.dark20,
    borderRadius: border.radius.sm,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: theme.spacing(1).toString(),
    marginRight: theme.spacing(3),
    marginTop: '2px',
    padding: theme.spacing(.5),
  },
  [`& .${classes.title}`]: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`& .${classes.spacer}`]: {
    flex: 1,
  },
}))

export default AppBar
