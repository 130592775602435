import UserProfileIcon from '@mui/icons-material/AccountCircle'
import UserProfileShow from './UserProfile.show'
import UserProfileEdit from './UserProfile.edit'

const resource = {
  icon: UserProfileIcon,
  show: UserProfileShow,
  edit: UserProfileEdit,
}

export default resource
