import { useAuth0 } from '@auth0/auth0-react'
import { Admin as ReactAdmin, CustomRoutes, localStorageStore } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import Layout from '../layout/Layout'
import providers from '../providers'
import LoginPage from '../pages/Login'
import { resourcesForScopes } from './Resources'
import { theme as xxTheme } from '../layout/themes'
import { useAuth0User } from '../auth0/useAuth0User'
import { Permissions } from './../types/ecoTypes'
import Unauthorized from '../pages/Unauthorized'

const store = localStorageStore()
store.setItem('sidebar.open', false)

const Admin = (): JSX.Element => {
  useAuth0User()
  const auth0 = useAuth0()

  return (
    <BrowserRouter>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ReactAdmin
        i18nProvider={providers.i18n}
        authProvider={providers.auth(auth0)}
        dataProvider={providers.data}
        layout={Layout}
        loginPage={LoginPage}
        disableTelemetry
        theme={xxTheme}
        store={store}
      >
        { (permissions: Permissions): JSX.Element[] => resourcesForScopes(permissions) }
        <CustomRoutes>
          <Route path="/unauthorized" element={<Unauthorized />} />
        </CustomRoutes>
      </ReactAdmin>
    </BrowserRouter>
  )
}

export default Admin
