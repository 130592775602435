import AccountCircle from '@mui/icons-material/AccountCircle'
import {
  Avatar, Button, IconButton,
  Menu, PopoverOrigin, Tooltip, useMediaQuery
} from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useAuthProvider, useGetIdentity, useTranslate } from 'ra-core'
import { ReactNode, useCallback, useState } from 'react'
import { Logout } from 'react-admin'
// import { ConfigurationMenu } from '../components'

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const UserMenu = (props: UserMenuProps): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState(null)
  const translate = useTranslate()
  const { isLoading, identity } = useGetIdentity()
  const authProvider = useAuthProvider()
  const isLargeEnough = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm')
  )

  const {
    children = !!authProvider ? <Logout /> : null,
    className,
    label = 'ra.auth.user_menu',
    icon = defaultIcon,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = useCallback(() => setAnchorEl(null), [])
  if (!children) return null
  const open = Boolean(anchorEl)

  return (
    <Root className={clsx(classes.root, className)}>
      {!isLargeEnough && !isLoading && identity?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={classes.button}
          color="inherit"
          startIcon={
            identity.avatar ? (
              <Avatar
                className={classes.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )
          }
          onClick={handleMenu}
        >
          {identity.fullName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-haspopup={true}
            color="inherit"
            onClick={handleMenu}
            size="large">
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        open={open}
        onClose={handleClose}
      >
        {/* <ConfigurationMenu /> */}
        {children}
      </Menu>
    </Root>
  )
}

const defaultIcon = <AccountCircle />

interface UserMenuProps {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  label?: string;
}

UserMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.node,
}

const PREFIX = 'xxllncUserMenu'

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  avatar: `${PREFIX}-avatar`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.button}`]: {
    textTransform: 'none',
  },
  [`& .${classes.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))
