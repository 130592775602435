import PropTypes from 'prop-types'
import { InputProps } from 'ra-core'

/**
 * Common PropTypes for all react-admin inputs
 */
export const InputPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  source: PropTypes.string,
}

export type CommonInputProps = InputProps & {
  cellClassName?: string,
  /*
   * @deprecated this property is not used anymore
   */
  formClassName?: string,
  fullWidth?: boolean,
  headerCellClassName?: string,
  margin?: 'none' | 'dense' | 'normal',
  variant?: 'standard' | 'outlined' | 'filled',
}

export const sanitizeInputRestProps: (props: any) => any = ({
  afterSubmit,
  allowEmpty,
  allowNull,
  alwaysOn,
  basePath,
  beforeSubmit,
  component,
  data,
  defaultValue,
  error,
  format,
  formatOnBlur,
  formClassName,
  initialValue,
  initializeForm,
  input,
  isEqual,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  multiple,
  name,
  options,
  optionText,
  optionValue,
  parse,
  record,
  ref,
  render,
  resource,
  source,
  submitError,
  subscription,
  textAlign,
  translate,
  translateChoice,
  type,
  validate,
  validateFields,
  value,
  ...props
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
}) => props
