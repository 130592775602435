import { DataProvider } from 'react-admin'
import { getEnvVariable } from 'xxllnc-react-components'
import authProvider from './auth'
import { mockDataProvider, xxllncDataProvider, getUrl } from './data'
import { i18nDutch, i18nEnglish } from '../i18n'
import polyglotI18nProvider from 'ra-i18n-polyglot'

const getDataProvider = (): DataProvider => {
  const mock = getEnvVariable('REACT_APP_MOCK_REQUESTS') === 'true' || false
  const test = getEnvVariable('NODE_ENV') === 'test'

  if (mock)
    console.log('mock is set to true!')

  return !mock && !test ? xxllncDataProvider : mockDataProvider
}

const messages = {
  en: { ...i18nEnglish },
  nl: { ...i18nDutch },
}

const i18nProvider = polyglotI18nProvider(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  locale => (messages[`${locale}`] ? messages[`${locale}`] : messages.en),
  'nl'
)

const providers = {
  auth: authProvider,
  data: getDataProvider(),
  i18n: i18nProvider,
  url: getUrl(),
}

export default providers
