import { Grid } from '@mui/material'
import { Edit, SimpleForm } from 'react-admin'
import UserProfileForm from './UserProfileForm'
import { HeaderTitle, ActionsToolbar, SimpleSaveToolbar } from '../../components'

const UserProfileEdit = (): JSX.Element => (
  <Edit
    title={<HeaderTitle source="name" i18n="edit.title" />}
    actions={<ActionsToolbar source="name" i18n="resources.userprofile.edit" />}
    redirect="show"
  >
    <SimpleForm
      toolbar={<SimpleSaveToolbar />}
    >
      <Grid container>
        <UserProfileForm />
      </Grid>
    </SimpleForm>
  </Edit>
)

export default UserProfileEdit
