import { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import { useTranslate } from 'ra-core'
import { xxllncColor, border } from './themes'

const SubMenu = (props: Props): JSX.Element | null => {
  const translate = useTranslate()
  const {
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense
  } = props

  const header = (
    <MenuItem className={classes.header} dense={dense} onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {icon}
      </ListItemIcon>
      <Typography variant="inherit" className={classes.title}>
        {translate(name)}
      </Typography>
      <div className={classes.dropdown}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </div>
    </MenuItem>
  )

  return children ? (
    <Root className={classes.root} {...props}>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Root>
  ) : null
}

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
  children?: React.ReactNode;
}

interface StyleProps {
  isOpen?: boolean,
  sidebarIsOpen?: boolean,
}

const PREFIX = 'xxllncSubMenu'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  dropdown: `${PREFIX}-dropdown`,
  icon: `${PREFIX}-icon`,
  sidebarIsOpen: `${PREFIX}-sidebarIsOpen`,
  sidebarIsClosed: `${PREFIX}-sidebarIsClosed`,
}

const Root = styled('div')(({ theme, isOpen, sidebarIsOpen }: StyleProps & { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: isOpen ? xxllncColor.dark10 : 'transparent',
    borderRadius: border.radius.md,
    overflow: 'hidden',
    ['& .MuiList-root']: {
      paddingLeft: theme.spacing(.5),
      paddingRight: theme.spacing(.5),
      paddingBottom: theme.spacing(.5),
      ['& .MuiListItem-button']: {
        padding: sidebarIsOpen ? theme.spacing(2).toString() + ' !important' : '9px !important',
      },
    },
    ['& .MuiMenuItem-root']: {
      height: 40,
      marginBottom: 0,
      marginTop: theme.spacing(1.5),
    }
  },
  [`& .${classes.header}`]: {
    backgroundColor: isOpen ? xxllncColor.dark80 : 'transparent',
    borderRadius: border.radius.md,
    display: 'flex',
    height: '48px !important',
    color: isOpen ? xxllncColor.light100 : xxllncColor.dark60,
    paddingLeft: sidebarIsOpen ? theme.spacing(2) : theme.spacing(1.5),
    paddingRight: sidebarIsOpen ? theme.spacing(2) : theme.spacing(1.5),
    marginTop: '0px !important',
    marginBottom: isOpen ? theme.spacing(1.5) : 0,
    ['&:hover']: {
      backgroundColor: isOpen ? xxllncColor.dark100 : 'rgba(0, 0, 0, 0.04)',
    }
  },
  [`& .${classes.title}`]: {
    color: isOpen ? xxllncColor.light100 : xxllncColor.dark60,
  },
  [`& .${classes.dropdown}`]: {
    display: 'flex',
    marginLeft: 'auto',
  },
  [`& .${classes.icon}`]: {
    fill: isOpen ? xxllncColor.light100 : xxllncColor.dark60,
    minWidth: theme.spacing(5),
    ['& .MuiSvgIcon-root']: {
      fill: isOpen ? xxllncColor.light100 : xxllncColor.dark60,
    }
  },
  [`& .${classes.sidebarIsOpen}`]: {
    ['& a']: {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  [`& .${classes.sidebarIsClosed}`]: {
    ['& a']: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}))

export default SubMenu
