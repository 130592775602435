import { UserNotification } from '../../types/ecoRequests'

export const mockNotification: UserNotification[] = [
  {
    id: 'auth0|60b8b386239e8b0070230c9b',
    appNotifications: [
      {
        name: 'Zaaksysteem',
        appId: '42d06e6a-a49e-49f3-be38-3606169bedee', // Zaaksysteem
        eventNotifications: [
          {
            label: 'this is a label',
            eventId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            object: 'This is an object',
            action: 'This is an action',
            enabled: true
          }
        ]
      }
    ]
  }
]
