import { PaletteOptions, ThemeOptions } from '@mui/material'
import { defaultTheme } from 'react-admin'
import { raOverrides } from './theme/raOverrides'
import { muiOverrides } from './theme/muiOverrides'
import { border } from './theme/border'
import { color, xxllncColor } from './theme/color'
import { typography } from './theme/typography'

export * from './theme/border'
export * from './theme/button'
export * from './theme/color'
export * from './theme/typography'

interface ITheme extends ThemeOptions {
  palette?: PaletteOptions & {
    common: {
      [key: string]: string
    }
  }
  sidebar: {
    width: number
    closedWidth: number
  }
}

export const theme: ITheme = {
  ...defaultTheme,
  typography: {
    fontFamily: typography.body,
    h6: {
      color: xxllncColor.dark100,
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '28px',
    },
    subtitle1: {
      color: xxllncColor.dark100,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
  palette: {
    common: {
      black: color.black,
      white: color.white,
    },
    primary: {
      main: color.primary.main,
      contrastText: color.primary.contrastText,
    },
    secondary: {
      light: color.primary.light,
      main: color.primary.main,
      dark: color.primary.dark,
      contrastText: color.primary.contrastText,
    },
    background: {
      default: xxllncColor.gallery,
    },
    text: {
      primary: color.primary.text,
    },
    error: {
      main: xxllncColor.red100,
    },
    mode: 'light' as unknown as 'light',
  },
  sidebar: {
    width: 356, // The default value is 240
    closedWidth: 74, // The default value is 55
  },
  shape: {
    borderRadius: border.radius.md,
  },
  components: {
    ...defaultTheme.components,
    ...raOverrides,
    ...muiOverrides,
  },
}
