import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import History from '@mui/icons-material/History'
import { useAuthenticated, useTranslate } from 'ra-core'
import { Title } from 'react-admin'

const goBack = (): void => {
  window.history.go(-1)
}

const Unauthorized = (props: UnauthorizedProps): JSX.Element => {
  const {
    className,
  } = props
  const translate = useTranslate()
  useAuthenticated()

  return (
    <Root
      className={clsx(classes.root, className)}
    >
      <Title defaultTitle={translate('page.unauthorized.headerTitle')} />
      <div className={classes.message}>
        <LockIcon className={classes.icon} />
        <h1>{translate('page.unauthorized.title')}</h1>
        <div>{translate('page.unauthorized.text')}</div>
      </div>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          startIcon={<History />}
          onClick={goBack}
        >
          {translate('ra.action.back')}
        </Button>
      </div>
    </Root>
  )
}

interface UnauthorizedProps {
  classes?: void,
  className?: string,
}

Unauthorized.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
}

const PREFIX = 'xxllncUnauthorized'

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`,
  toolbar: `${PREFIX}-toolbar`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
      marginTop: '-3em',
    },
  },
  [`& .${classes.icon}`]: {
    width: '9em',
    height: '9em',
  },
  [`& .${classes.message}`]: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em',
  },
  [`& .${classes.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}))

export default Unauthorized
