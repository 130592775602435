import { fetchUtils } from 'ra-core'
import { getEnvVariable } from 'xxllnc-react-components'
import { debug } from '../test'
import { OrganizationBase } from '../types/ecoTypes'

const mockFetch = (): OrganizationBase | null => {
  if (getEnvVariable('REACT_APP_MOCK_ORGANIZATION_FETCH').toLowerCase() === 'true')
    return {
      auth0Identifier: getEnvVariable('REACT_APP_MOCK_ORGANIZATION_FETCH_ID', ''),
      organizationId: 'exxellence',
    }

  return null
}

const isOrg = (object: OrganizationBase) => Object.prototype.hasOwnProperty.call(object, 'auth0Identifier')
  && Object.prototype.hasOwnProperty.call(object, 'organizationId')

const organizationFetch = async (url: string, email: string, appId?: string): Promise<any> => {
  const mock = mockFetch()
  if (mock) return Promise.resolve(mock)

  const data = appId ?
    await fetchUtils.fetchJson(`${url}/login_info?filter={"emailAddress":"${email}","appId":"${appId}"}`) :
    await fetchUtils.fetchJson(`${url}/login_info?filter={"emailAddress":"${email}"}`)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (data.json && data.json.length > 1) {
    return Promise.resolve(data.json)
  }

  debug && console.log('Only one organization remains', data)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return (data.json[0] && isOrg(data.json[0])) ? Promise.resolve(data.json[0]) : Promise.reject()
}

export default organizationFetch
