import { Resource } from 'react-admin'
import userprofile from './UserProfile'
import { Permissions } from './../types/ecoTypes'
import { debug } from '../test'

const availableResources = ({scopes}: Permissions): JSX.Element[] => [
  <Resource
    name="user_profile"
    icon={userprofile.icon}
    list={userprofile.show}
    show={userprofile.show}
    edit={
      scopes.some((scope: string) => scope === 'eco.user.notification:write') ?
        userprofile.edit : undefined}
    options={{
      label: 'Gebruikersprofiel',
      scopes: ['eco.user.notification:read']
    }}
  />,
]

const fallback = (): JSX.Element[] => [
  <Resource
    name="user_profile"
    icon={userprofile.icon}
    list={userprofile.show}
    show={userprofile.show}
  />
]

export const resourcesForScopes = ({ scopes }: Permissions): JSX.Element[] => {
  debug && console.log({scopes})
  if (!scopes || scopes.length === 0) {
    console.warn('No scopes received, returning fallback resource')
    return fallback()
  }
  const resources = availableResources({ scopes })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    .filter(resource => resource.props.options.scopes.find(scope => scopes.includes(scope)) !== undefined)
  if (!resources || resources.length === 0) {
    console.warn('No resources received, returning fallback resource')
    return fallback()
  }
  return resources
}
