import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { RaRecord, TopToolbar } from 'react-admin'
import { SvgField, ToolbarTitle } from '..'
import { BackButton } from '../../components'

export const ActionsToolbar = (props: ActionsToolbarProps): JSX.Element => {
  const {
    children,
    i18n,
    icon,
    record,
    source,
    tag,
    to,
    hideBackbutton,
    ...rest
  } = props

  return (
    <StyledTopToolbar {...rest} className={classes.toolbar}>
      {hideBackbutton ? null : <BackButton to={to} />}
      {icon && (
        <div className={classes.iconContainer}>
          <SvgField source={icon} />
        </div>
      )}
      <ToolbarTitle record={record} source={source} i18n={i18n} className={classes.title} />
      {tag}
      <span className={classes.spacer} />
      {children}
    </StyledTopToolbar>
  )
}

interface ActionsToolbarProps {
  children?: React.ReactNode;
  classes?: {
    iconContainer?: string;
    spacer?: string;
    title?: string;
    toolbar?: string;
  };
  hideBackbutton?: boolean;
  i18n?: string;
  icon?: string;
  record?: RaRecord;
  source?: string;
  tag?: React.ReactNode;
  to?: string;
}

ActionsToolbar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  hideBackbutton: PropTypes.bool,
  i18n: PropTypes.string,
  icon: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  sx: PropTypes.any,
  tag: PropTypes.node,
  to: PropTypes.string,
}

const PREFIX = 'xxllncActionsToolbar'

const classes = {
  iconContainer: `${PREFIX}-iconContainer`,
  spacer: `${PREFIX}-spacer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
}

const StyledTopToolbar = styled(TopToolbar)(({ theme }) => ({
  [`&.${classes.toolbar}`]: {
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
  },
  [`& .${classes.spacer}`]: {
    flex: 1,
  },
  [`& .${classes.title}`]: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    marginRight: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`& .${classes.iconContainer}`]: {
    borderRadius: 8,
    height: 32,
    lineHeight: 0,
    marginRight: theme.spacing(1),
    maxWidth: 32,
    overflow: 'hidden',
    ['& img']: {
      height: '100%',
      width: '100%',
    }
  },
}))
