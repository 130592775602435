import { AuthProvider } from 'ra-core'
import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context'
import { getScope } from '../utils/getScope'
import { getFromToken } from '../utils/fromToken'
import authToken from '../utils/authToken'
import { debug, mock } from '../test'

const clearLocalStorage = (): void => {
  authToken.deleteToken()
  authToken.deleteOrganization()
  localStorage.removeItem('nickname')
}

const authProvider = (auth0: Auth0ContextInterface): AuthProvider => ({
  login: (): Promise<any> => Promise.resolve(),
  logout: (): Promise<any> => {
    if (auth0.isAuthenticated) auth0.logout({ federated: false })
    clearLocalStorage()
    return Promise.resolve()
  },
  checkError: (error: { status: number }): Promise<any> => {
    const { status } = error
    debug && console.log({ status })
    if (status === 401) {
      debug && console.log('You are not authenticated, logging you out')
      clearLocalStorage()
      if (auth0.isAuthenticated) void auth0.logout()
      return Promise.reject()
    }
    if (status === 403) {
      debug && console.log('You are unauthorized')
      return Promise.reject({ redirectTo: '/unauthorized', logoutUser: false })
    }
    return Promise.resolve()
  },
  checkAuth: async (): Promise<any> => {
    if (mock() || authToken.getToken()) return Promise.resolve()
    if (!auth0.isAuthenticated) return Promise.reject({ message: false })
    auth0.logout({ federated: false })
    return Promise.reject({ message: false })
  },
  getPermissions: (): Promise<any> => Promise.resolve({ scopes: getScope() as [] }),
  getIdentity: (): Promise<any> => {
    if (mock()) return Promise.resolve({
      id: 1,
      fullName: 'Tessa tester',
      organizationId: 'bf585e6d-e641-4ef7-be55-9d3a7054773a'
    })
    if (!auth0.user) {
      return Promise.resolve({
        id: 1,
        fullName: localStorage.getItem('nickname') || ''
      })
    }
    localStorage.setItem('nickname', auth0.user.nickname || '')
    return Promise.resolve({
      id: auth0.user.sub || '',
      fullName: auth0.user.name || auth0.user.nickname || '',
      organizationId: getFromToken('organization_uuid') || '',
    })
  },
})

export default authProvider
