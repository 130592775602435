/* eslint-disable */
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
  DataProvider,
} from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'ra-core'
import { getEnvVariable } from 'xxllnc-react-components'
import provider from './'
import mocks from '../mocks'
import { GetParams } from '../mocks/mockProvider'
import authToken from '../utils/authToken'
import { debug } from '../test'

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertToBase64 = blob => new Promise(async (resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject

  reader.readAsDataURL(blob.rawFile)
})

export const httpClient = (url: string, options: RequestInit = {}): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
    options.headers.set('accept-language', 'nl')
  }

  const user = {
    authenticated: true,
    token: authToken.getToken() || '',
  }

  if (!user.token || user.token === '') return Promise.reject()

  return fetchUtils.fetchJson(url, { ...options, user })
}

export const getUrl = (): string => getEnvVariable('REACT_APP_SERVER_URL', '')
const getControlpanelUrl = (): string => getEnvVariable('REACT_APP_CONTROLPANEL_SERVER_URL', '')
export const getApiUrl = (resource: string): string => resource.startsWith('login_info') ? getControlpanelUrl() : getUrl()

debug && console.log({ getUrl: getUrl(), getControlpanelUrl: getControlpanelUrl() })

const dataProvider: DataProvider = simpleRestProvider(getUrl(), httpClient)
const controlpanelProvider = simpleRestProvider(getControlpanelUrl(), httpClient)

interface CreateManyParams {
  data: any[];
}

export const xxllncDataProvider: DataProvider = {
  ...dataProvider,
  get: async (resource: string) => httpClient(`${getApiUrl(resource)}/${resource}`, {
    method: 'GET',
  }).then(({ json }) => ({ data: json })),
  getList: (resource: string, params: GetListParams) =>
    resource.startsWith('login_info') ? controlpanelProvider.getList(resource, params) : dataProvider.getList(resource, params),
  create: (resource: string, params: CreateParams) => {
    if (!params.data.icon) {
      return dataProvider.create(resource, params)
    }

    if (!(params.data.icon.rawFile instanceof File)) {
      throw new Error('The rawFile is not a File object.')
    }

    return convertToBase64(params.data.icon).then((icon: any) => dataProvider.create(resource, {
      ...params,
      data: {
        ...params.data,
        icon: icon.replace('data:image/svg+xml;base64,', ''),
      },
    }))
  },
  createMany: async (resource: string, params: CreateManyParams) => {
    debug && console.log('createMany called successfully', { resource, params })
    return params.data.map(item => dataProvider.create(resource, {
      data: {
        ...item
      }
    }))
  },
  update: (resource: string, params: UpdateParams) => {
    if (params.data.icon && typeof params.data.icon != 'string') {
      if (params.data.icon.rawFile && !(params.data.icon.rawFile instanceof File)) {
        throw new Error('The rawFile is not a File object.')
      }

      return convertToBase64(params.data.icon).then((icon: any) => dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          icon: icon.replace('data:image/svg+xml;base64,', ''),
        },
      }))
    }

    return dataProvider.update(resource, params)
  },
}

export const mockDataProvider: DataProvider = {
  get: async (resource: string, params: GetParams) => mocks.mockProvider.get(resource, params),
  getList: async (resource: string, params: GetListParams) => mocks.mockProvider.getList(resource, params),
  getOne: (resource: string, params: GetOneParams) => mocks.mockProvider.getOne(resource, params),
  getMany: (resource: string, params: GetManyParams) => mocks.mockProvider.getMany(resource, params),
  create: (resource: string, params: CreateParams) => mocks.mockProvider.create(resource, params),
  update: (resource: string, params: UpdateParams) => mocks.mockProvider.update(resource, params),
  delete: (resource: string, params: DeleteParams) => mocks.mockProvider.delete(resource, params),
  getManyReference: (resource: string, params: GetManyReferenceParams) => mocks.mockProvider.getManyReference(resource, params),
  /* Below is not implemented yet */
  updateMany: (resource: string, params: UpdateManyParams) =>
    httpClient(`${provider.url}/${resource}/${JSON.stringify(params.ids)}`).then(response => ({ data: response.json })),
  deleteMany: (resource: string, params: DeleteManyParams) =>
    httpClient(`${provider.url}/${resource}/${JSON.stringify(params.ids)}`).then(response => ({ data: response.json }))
}
