import { useEffect } from 'react'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import authToken from '../utils/authToken'

export const useAuth0User = (): Auth0ContextInterface => {
  const auth0 = useAuth0()
  const { organization, domain } = authToken.getOrganization()
  const { isAuthenticated, loginWithRedirect, isLoading } = auth0
  const token = authToken.getToken()

  useEffect(() => {
    if (!isAuthenticated && token && !isLoading && organization?.auth0Identifier) {
      void (async () => {
        // eslint-disable-next-line camelcase
        await loginWithRedirect({organization: organization.auth0Identifier, login_hint: domain})
      })()
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, token])

  return auth0
}
